html {
  overflow-y: auto;
  overflow-x: hidden;
}

body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.center-cell {
  height: 32px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.counter {
  background: black;
  color: white;
  text-align: center;
  font-family: "Gotham Rounded A", "Gotham Rounded B", Helvetica, Arial,
    sans-serif;
}

.line-odd {
  background: #555;
}

.line-even {
  background: #666;
}

.line {
  height: 58px;
}

.block {
  position: absolute;
  border: 0;
  canvas {
    cursor: grab;
  }
  border-style: "inset";
}

#cursorTimeline {
  background: white;
  width: 2px;
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  box-shadow: 0 0 2px #555;
}

.list-layers {
  border-right: 2px #444 solid;
  float: left;
  width: 100%;
  input[type="text"] {
    color: white;
    background: none;
    border: 0;
    width: 95%;
    &:hover {
      background: #444;
    }
    &:focus {
      outline: none;
      background: #555;
      box-shadow: none;
    }
  }

  .label {
    font-size: 0.75em;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .buttons {
    float: right;

    button {
      border-style: solid;
      border-width: 0px 0px 2px;
      box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
      color: #ffffff;
      background: #555;
      border-radius: 6px;
      cursor: pointer;
      display: inline-block;
      font-style: normal;
      overflow: hidden;
      text-align: center;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: all 200ms ease-in-out 0s;
      white-space: nowrap;
      font-weight: 700;
      font-size: 0.65em;
      margin-right: 5px;
      width: fit-content;

      &.mute {
        color: white;
        background: rgb(245, 0, 87);
        border-color: #ee3333;
      }

      &.solo {
        color: white;
        background: #33aa33;
        border-color: #22bb22;
      }
    }

    button:focus {
      outline: none;
      box-shadow: none;
    }

    button:hover {
      color: white;
      background: #888;
    }
  }

  .cell {
    color: #eee;
  }

  .layer {
    background: rgb(85, 85, 85);
    background: linear-gradient(
      180deg,
      rgba(85, 85, 85, 1) 0%,
      rgba(69, 69, 69, 1) 15%,
      rgba(51, 51, 51, 1) 100%
    );
    border: 2px #333 solid;
    &.selected {
      border-left-color: red;
    }
  }
}

#blocks {
  #canvasTimer {
    cursor: pointer !important;
  }
}

.titleShadow {
  color: white;
  text-shadow: 0px 3px 0px #b2a98f,
  0px 14px 10px rgba(0,0,0,0.15),
  0px 24px 2px rgba(0,0,0,0.1),
  0px 34px 30px rgba(0,0,0,0.1);
}

footer {
  padding: 20px;
  color: #aaa;
  a {
    color: #aaa;
  }
  background: #222;
  strong {
    color: white;
  }
}
